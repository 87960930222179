<template>
  <div class="modern-color-theme font-poppins flex flex-col gap-2" data-component-name="VNumber">
    <VSLabel v-if="props.label" :tooltip="props.labelTooltip" :for="id">{{ props.label }}</VSLabel>
    <div
      class="flex items-center rounded-md ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600"
      :class="computedWrapperClass"
    >
      <div v-if="slots.prefix" class="pl-2"><slot name="prefix" /></div>
      <input
        :id="id"
        ref="focusElement"
        v-model="modelValueInternalRaw"
        :disabled="props.disabled"
        :readonly="props.readonly"
        :placeholder="props.placeholder"
        :maxlength="props.maxLength"
        :max="props.max"
        :min="props.min"
        :step="props.step"
        :name="props.name"
        class="w-full rounded-md m-[2px] pl-2.5 !border-none focus:ring-0 !shadow-none py-[calc(0.375rem-2px)] shadow-sm placeholder:text-neutral-400 text-sm leading-6"
        :class="computedInputClass"
        :aria-describedby="descriptionId"
        @change="handleChange"
        @keydown="handleKeyDown"
        @paste="handlePaste"
        @keydown.enter="updateModelValue"
        @blur="updateModelValue"
      >
      <div v-if="props.maxLength" class="pr-4 whitespace-nowrap text-xs leading-4 font-normal" :class="{ 'text-neutral-500': !props.disabled, 'text-neutral-250': props.disabled }">
        {{ modelValueInternalLength }} / {{ props.maxLength }}
      </div>
      <div v-if="slots.suffix" class="pr-2"><slot name="suffix" /></div>
    </div>
    <VSValidation v-if="validationResult" :type="validationResult[0]" :message="validationResult[1]" />
    <VSDescription v-if="props.description" :id="descriptionId">{{ props.description }}</VSDescription>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, useSlots, watch } from 'vue';
import { isControlCharacter, isNullOrUndefined, isNumberLikeCharacter } from '../../utils/validations';
import VSDescription from './components/VSDescription.vue'
import VSLabel from './components/VSLabel.vue'
import { useElementId, useDescriptionElementId } from '../../utils/utils';
import { useFocus } from '@component-utils/focus';
import { stopAndPrevent } from '~/features/_abstract/utils/event';
import { Key } from '@avvoka/shared';
import { SHARED_PROPS_DEFAULTS, VALIDATION_PROPS_DEFAULTS, type SharedProps, type ValidationProps } from './helpers/types';
import VSValidation from './components/VSValidation.vue';
import { createDefaultValidation, useValidation } from './helpers/validations';

defineOptions({
  name: 'VNumber'
})

const slots = useSlots()

const props = withDefaults(
  defineProps<SharedProps & ValidationProps<number> & {
    placeholder?: string
    focus?: boolean
    step?: number
    min?: number
    max?: number
    maxLength?: number
    centered?: boolean
    required?: boolean
    buffered?: boolean
    allowEmpty?: boolean
  }>(),
  {
    ...SHARED_PROPS_DEFAULTS,
    ...VALIDATION_PROPS_DEFAULTS,
    focus: false,
    min: undefined,
    max: undefined,
    step: undefined,
    required: undefined,
    maxLength: undefined,
    placeholder: undefined,
    buffered: false,
    centered: false,
    allowEmpty: false
  }
)

const id = useElementId(props.id)
const descriptionId = useDescriptionElementId(props.description, id.value)

const modelValue = defineModel<undefined | null | number>({ required: true })

const precision = computed(() => Math.pow(10, String(props.step ?? 1).split('.')[1]?.length ?? 0))
watch(precision, (newValue, oldValue) => {
  if (modelValueInternal.value) {
    modelValueInternal.value = modelValueInternal.value / oldValue * newValue
  }
})

const modelValueInternal = ref(modelValue.value ? Math.trunc((modelValue.value ?? props.min ?? 0) * precision.value) : undefined)
const modelValueInternalRaw = ref(String(modelValue.value ?? ''))

watch(modelValue, (value) => {
  modelValueInternalRaw.value = String(value ?? '')
  modelValueInternal.value = Math.trunc((value ?? props.min ?? 0) * precision.value)
})

watch(modelValueInternal, (value) => {
  if (!props.buffered) modelValue.value = isNullOrUndefined(value) ? value : (value / precision.value)
})

const modelValueInternalLength = computed(() => modelValueInternalRaw.value.length)

const updateModelValue = () => {
  if (props.buffered) {
    const value = modelValueInternal.value
    if (isNullOrUndefined(value)) {
      modelValueInternalRaw.value = ''
      modelValue.value = undefined
    } else {
      modelValueInternalRaw.value = String(modelValue.value = value / precision.value)
    }
  }
}

const modelValueForValidation = computed(() => isNullOrUndefined(modelValueInternal.value) ? undefined : modelValueInternal.value / precision.value)

const { validationVisible, validationResult, isValid, isValidationError, isValidationWarning } = useValidation<number>(
  modelValueForValidation,
  props,
  createDefaultValidation<number>(props)
)

const computedInputClass = computed(() => ({
  'text-neutral-400 bg-neutral-150': props.disabled,
  'text-neutral-950 bg-neutral-100': !props.disabled,
  'text-center': props.centered,
}))

const computedWrapperClass = computed(() => ({
  'text-neutral-400 bg-neutral-150 ring-neutral-200': props.disabled,
  'text-neutral-950 bg-neutral-100 ring-neutral-300': !props.disabled,
  'ring-red-300': isValidationError.value,
  'ring-yellow-300': isValidationWarning.value
}))

const { focus, focusElement } = useFocus(props.focus)

const handleChange = () => {
  const rawValue = modelValueInternalRaw.value

  const isEmpty = rawValue === '' || rawValue === '-'

  const unscaledValue = isEmpty ? (props.min ?? 0) : parseFloat(rawValue)
  let value = typeof props.step === 'number' ? Math.trunc(unscaledValue * precision.value) : unscaledValue

  if (typeof props.max === 'number' && value > props.max * precision.value) {
    value = props.max * precision.value
  }

  if (typeof props.min === 'number' && value < props.min * precision.value) {
    value = props.min * precision.value
  }

  if (isEmpty && props.allowEmpty) {
    modelValueInternal.value = undefined
  } else {
    modelValueInternal.value = value
    modelValueInternalRaw.value = String(value / precision.value)
  }
}

const handlePaste = (event: ClipboardEvent) => {
  stopAndPrevent(event)

  if (props.readonly || props.disabled) return

  const clipboardData = event.clipboardData
  if (!clipboardData) return

  const value = parseFloat(clipboardData.getData('text/plain') ?? '')
  if (!isNaN(value)) {
    const stringifiedValue = modelValueInternalRaw.value = value.toString().slice(0, props.maxLength ?? Infinity)

    const element = focusElement.value as HTMLInputElement
    element.selectionDirection = 'none'
    element.selectionStart = stringifiedValue.length
    element.selectionEnd = stringifiedValue.length

    handleChange()

    updateModelValue()
  }
}

const handleKeyDown = (event: KeyboardEvent) => {
  validationVisible.value = true

  const element = focusElement.value as HTMLInputElement
  const key = event.key

  if (key === '-') {
    // Disallow typing of 'minus' when min is equal or above 0
    if (typeof props.min === 'number' && props.min >= 0) {
      return stopAndPrevent(event)
    }

    const isAtLeastFirstCharacterSelected = element.selectionStart === 0 && (element.selectionEnd && element.selectionEnd > 0)
    
    // Disallow typing of 'minus' outside of first position or if number is already negative
    if (!isAtLeastFirstCharacterSelected && (element.selectionStart !== 0 || element.value[0] === '-')) {
      return stopAndPrevent(event)
    }
  } else if (key === '.') {
    // If step is 1 or above, disallow typing dot
    if (typeof props.step === 'number' && precision.value <= 1) {
      return stopAndPrevent(event)
    }

    // Disallow typing of 'dot' if there is already a dot present, allow if existing dot is selected
    const dotPosition = element.value.indexOf('.')
    const minusPosition = element.value.indexOf('-')

    if (dotPosition !== -1) {
      const selStart = element.selectionStart
      const selEnd = element.selectionEnd

      if (!selStart || !selEnd || selStart > dotPosition || selEnd < dotPosition) {
        return stopAndPrevent(event)
      }
    }

    if (minusPosition !== -1) {
      const selStart = element.selectionStart
      const selEnd = element.selectionEnd

      if (selStart === 0 && (!selEnd || selStart === selEnd)) {
        return stopAndPrevent(event)
      }
    }
  }

  if (event.ctrlKey || event.altKey || event.metaKey) {
    return
  }

  if (isNumberLikeCharacter(key)) {
    // Let them type
  } else if (isControlCharacter(key as Key)) {
    // Handle arrow up and down
    if (key === Key.ArrowUp || key === Key.ArrowDown) {
      stopAndPrevent(event)

      const modificator = key === Key.ArrowUp ? +1 : -1

      const value = (modelValueInternal.value ?? ((props.min ?? 0) * precision.value)) + modificator * (props.step ?? 1) * precision.value

      modelValueInternalRaw.value = String(value / precision.value)

      handleChange()
    }
  } else {
    stopAndPrevent(event)
  }
}

defineExpose({
  focus,
  isValid
})
</script>