<template>
  <div class="modern-color-theme font-poppins flex flex-col gap-2" data-component-name="VInput">
    <VSLabel v-if="props.label" :tooltip="props.labelTooltip" :for="id">{{ props.label }}</VSLabel>
    <div
      class="flex items-center rounded-md ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600"
      :class="computedWrapperClass"
    >
      <div v-if="slots.prefix" class="pl-2"><slot name="prefix" /></div>
      <input
        :id="id"
        ref="focusElement"
        v-model.trim="modelValueInternal"
        :type="props.type"
        :disabled="props.disabled"
        :readonly="props.readonly"
        :maxlength="props.maxLength"
        :placeholder="props.placeholder"
        :name="props.name"
        class="w-full rounded-md m-[2px] pl-2.5 !border-none focus:ring-0 !shadow-none py-[calc(0.375rem-2px)] shadow-sm placeholder:text-neutral-400 text-sm leading-6"
        :class="computedInputClass"
        :aria-describedby="descriptionId"
        @keydown="handleKeyDown"
        @paste="handlePaste"
        @keydown.enter="updateModelValue"
        @blur="updateModelValue"
      >
      <div v-if="props.maxLength" class="pr-4 whitespace-nowrap text-xs leading-4 font-normal" :class="{ 'text-neutral-500': !props.disabled, 'text-neutral-250': props.disabled }">
        {{ modelValueInternalLength }} / {{ props.maxLength }}
      </div>
      <div v-if="slots.suffix" class="pr-2"><slot name="suffix" /></div>
    </div>
    <VSValidation v-if="validationResult" :type="validationResult[0]" :message="validationResult[1]" />
    <VSDescription v-if="props.description" :id="descriptionId">{{ props.description }}</VSDescription>
  </div>
</template>
<script lang="ts" setup>
import { computed, useSlots } from 'vue';
import VSDescription from './components/VSDescription.vue'
import VSLabel from './components/VSLabel.vue'
import VSValidation from './components/VSValidation.vue';
import { useElementId, useDescriptionElementId } from '../../utils/utils';
import { useFocus } from '@component-utils/focus';
import { useBuffering } from './helpers/buffering';
import { handleEvents } from './helpers/input';
import { SHARED_PROPS_DEFAULTS, VALIDATION_PROPS_DEFAULTS, type SharedProps, type ValidationProps } from './helpers/types';
import { createDefaultValidation, useValidation } from './helpers/validations';

defineOptions({
  name: 'VInput'
})

const slots = useSlots()

const props = withDefaults(
  defineProps<SharedProps & ValidationProps<string> & {
    placeholder?: string
    type?: 'text' | 'password' | 'email'
    focus?: boolean
    centered?: boolean
    required?: boolean
    maxLength?: number
    buffered?: boolean
    whitelist?: string[]
  }>(),
  {
    ...SHARED_PROPS_DEFAULTS,
    ...VALIDATION_PROPS_DEFAULTS,
    type: 'text',
    focus: false,
    centered: false,
    required: false,
    placeholder: undefined,
    maxLength: undefined,
    whitelist: undefined,
    buffered: false
  }
)

const id = useElementId(props.id)
const descriptionId = useDescriptionElementId(props.description, id.value)

const modelValue = defineModel<undefined | null | string>({ required: true })

const { modelValueInternal, updateModelValue } = useBuffering(modelValue, props)

const modelValueInternalLength = computed(() => modelValueInternal.value?.length ?? 0)

const { validationVisible, validationResult, isValid, isValidationError, isValidationWarning } = useValidation(
  modelValueInternal,
  props,
  createDefaultValidation<string>(props)
)

const computedInputClass = computed(() => ({
  'text-neutral-400 bg-neutral-150': props.disabled,
  'text-neutral-950 bg-neutral-100': !props.disabled,
  'text-center': props.centered,
}))

const computedWrapperClass = computed(() => ({
  'text-neutral-400 bg-neutral-150 ring-neutral-200': props.disabled,
  'text-neutral-950 bg-neutral-100 ring-neutral-300': !props.disabled,
  'ring-red-300': isValidationError.value,
  'ring-yellow-300': isValidationWarning.value
}))

const { focus, focusElement } = useFocus(props.focus)

const { handleKeyDown, handlePaste } = handleEvents(props, validationVisible)

defineExpose({
  focus,
  isValid
})
</script>