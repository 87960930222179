import { ValidationErrorSeverity, type ValidationResult } from "@component-utils/validations";
import { computed, ref, type Ref } from "vue";
import type { ValidationProps } from "./types";
import { globalLocalize, useLocalize } from "@component-utils/localization";
import Utils from "~/features/utils";
import type VInput from '../VInput.vue'
import type VPassword from '../VPassword.vue'
import type VSelect from "../VSelect.vue";
import type { ComponentExposed } from "vue-component-type-helpers";
import type VEmail from "../VEmail.vue";
import type VTextArea from "../VTextArea.vue";
import type VSmartInput from "../VSmartInput.vue";
import type VNumber from "../VNumber.vue";
import type VRadio from "../VRadio.vue";
import type VMultiSelect from "../VMultiSelect.vue";

export function useValidation<T> (
  validatorSubject: Ref<undefined | null | T>,
  props: ValidationProps<T>,
  validatorDefault?: Ref<(value: undefined | null | T) => ValidationResult>
) {
  const validator = computed(() => props.validator ?? validatorDefault?.value)

  const validationVisible = ref(props.validationTrigger === 'immediate')

  const validationResult = computed(() => validator.value && validator.value(validatorSubject.value) || null)
  const isValid = computed(() => !validationResult.value)

  const validationResultForDisplay = computed(() => validationVisible.value && validationResult.value || null)
  const isValidationError = computed(() => validationResultForDisplay.value?.[0] === ValidationErrorSeverity.Error)
  const isValidationWarning = computed(() => validationResultForDisplay.value?.[0] === ValidationErrorSeverity.Warning)

  return {
    // For display
    validationVisible,
    validationResult: validationResultForDisplay,
    isValidationError,
    isValidationWarning,
    // For expose, regardless of visibility
    isValid
  }
}

type ValidatedComponent = typeof VInput | typeof VPassword | typeof VEmail | typeof VTextArea | typeof VSmartInput | typeof VNumber
type ValidatedGenericComponent<T = unknown> = typeof VSelect<T> | typeof VRadio<T> | typeof VMultiSelect<T>

export function useComponentValidation<T extends ValidatedComponent | ComponentExposed<ValidatedGenericComponent<unknown>>> (...refs: Ref<T | null>[]) {
  return computed(() => refs.every(ref => ref.value?.isValid))
}

export function isValidationError (validationResult: Ref<ValidationResult>) {
  return validationResult.value?.[0] === ValidationErrorSeverity.Error
}

export function isValidationWarning (validationResult: Ref<ValidationResult>) {
  return validationResult.value?.[0] === ValidationErrorSeverity.Warning
}

export function validatePasswordRequirements(rawPassword: string | null | undefined) {
  const password = (rawPassword || '').trim()
  const localize = useLocalize('component-library.validations.password.requirements')

  return [
    {
      text: localize('min_length'),
      isValid: password.length >= 6
    },
    {
      text: localize('lowercase'),
      isValid: /[a-z]/.test(password)
    },
    {
      text: localize('uppercase'),
      isValid: /[A-Z]/.test(password)
    },
    {
      text: localize('number'),
      isValid: /[0-9]/.test(password)
    },
    {
      text: localize('special_char'),
      isValid: /[!@#$%^&*(),.?":{}|<>_]/.test(password)
    }
  ]
}

export function createDefaultValidation<T> (props: { required?: boolean, id?: string, showRequirements?: boolean }, type?: 'email' | 'password' | 'multiselect'): Ref<(value: T | undefined | null) => ValidationResult> {
  switch (type) {
    case 'multiselect': return computed(() => (value: T | undefined | null) => {
      if (!value || !Array.isArray(value) || value.length === 0) {
        if (props.required) return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.generic.empty')]
        else return null
      }
      return null
    })
    case 'email': return computed(() => (value: T | undefined | null) => {
      if (!value || typeof value !== 'string' || !value.trim()) {
        if (props.required) return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.email.empty')]
        else return null
      }
      if (!Utils.isEmailValid(value)) return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.email.format')]
      return null
    })
    case 'password': return computed(() => (value: T | undefined | null) => {
      if (!value || typeof value !== 'string' || !value.trim()) {
        if (props.required) return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.password.empty')]
        else return null
      }
      if (!props.showRequirements && value.trim().length < 6) {
        return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.password.format')]
      }
      return null
    })
    default: return computed(() => (value: T | undefined | null) => {
      if (!value || (typeof value === 'string' && !value.trim())) {
        if (props.required) return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.generic.empty')]
        else return null
      }
      return null
    })
  }
}
